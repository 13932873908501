
.Addvillage-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}
.Addvillage-head>h1{
    font-size: 2rem;
    font-family: quicksand;
    font-weight: 700;
    text-transform: capitalize;
    user-select: none;
    margin-bottom: 10px;
    margin-top: 10px;
}
.Addvillage-head>p{
    font-size: 1rem;
    font-weight: 600;
    font-family: Quicksand;
    text-transform: capitalize;
    color: grey;
    user-select: none;
}

.Addvillage-form{
    width: 380px;
    background-color:white;
    padding: 20px;
    margin: auto;
}

.Addvillage-form>div{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    user-select: none;
}

.Addvillage-form>div label{
    margin-bottom:4px;
    font-size: 1.2rem;
    font-family: quicksand;
    font-weight: 600;
    text-transform: capitalize;
}

.Addvillage-form>div input{
    height: 40px;
    border: none;
    border-bottom: 1px solid black;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 600;
    font-family: quicksand;
    width: 100%;
}
.Addvillage-form>div input::placeholder{
    color: gray;
    font-family: quicksand;
    font-weight: 600;
}
.Addvillage-form>div input:focus{
    outline: none;
}
.Addvillage-form>div .inactive-adv-btn{
    width: 100%;
    height: 40px;
    border: none;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: green;
    color: white;
    opacity: 0.5;
    pointer-events: none;
}

.Addvillage-form>div .active-adv-btn{
    width: 100%;
    height: 40px;
    border: none;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: green;
    color: white;
    cursor: pointer;
}

.Addvillage-form>div .active-adv-btn:hover{
    background-color: rgb(0, 95, 0);
}

.adv-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 300px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 20px;
    user-select: none;
}

.adv-search>img{
    width: auto;
    height: 20px;
}

.adv-search>input{
    height: 40px;
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    padding-left: 5px;
    font-size: 1.1rem;
    font-family: quicksand;
    font-weight: 600;
}
.adv-search input:focus{
    outline: none;
}
