/* Login Css */
.login-con{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form{
    width: 350px;
    user-select: none;
    padding: 10px;
   
}

.login-form>div{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 5px;
}

.login-form>div button{
    width: 140px;
    padding: 10px 20px;
    margin: auto;
    margin-top: 10px;
    border: none;
    background-color: #F7C531;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: Quicksand;
    border-top-right-radius: 23.12345px;
    border-top-left-radius: 19.4321px;
    border-bottom-left-radius: 14.3456px;
    border-bottom-right-radius: 15.56789px;
    user-select: none;
    cursor: pointer;
}

.login-form>div button:hover{
    background-color: #fcbd02;
}

.login-form>div label{
    font-size: 1.3rem;
    font-family: Quicksand;
    font-weight: 600;
    user-select: none;
}

.login-form>div input{
    height: 40px;
    padding-left: 10px;
    font-size: 1.1rem;
    font-family: Quicksand;
    font-weight: 500;
    border-top: hidden;
    border-right: hidden;
    border-left: hidden;
}

.login-form>div input:focus{
    outline: none;
    
}

.login-form>p{
    text-align: center;
    font-size: 1rem;
    font-family: Quicksand;
    font-weight: 500;
    text-transform: capitalize;
}

.login-form>p span{
    color:blue;
    text-decoration: underline;
    cursor: pointer;
}