.home-divs{
    min-height: 75vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 20px;
    user-select: none;
}

.home-divs-st{
    user-select: none;
}

.home-divs-st>h1{
    font-family: Quicksand;
    padding: 2px;
    margin-bottom: 3px;
    font-size: 3.5rem;
}

.home-divs-st>p{
    color: #8B8BA5;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
}

.home-divs-st>button{
    border: none;
    min-width: 150px;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #F7C531;
}

.home-divs-nd{
    width: 500px;
    height: 350px;
    clip-path: polygon(0 0,100% 0,85% 100%, 8% 100%);
    position: relative;


}

.home-divs-nd>img{
    width: 100%;
    height: 100%;
}

.home-divs-nd-con{
    width: 520px;
    height: 365px;
    background-color: #D8CBF6;
    margin-left: 20px;
    clip-path: polygon(0 0,100% 0,86% 100%, 3% 100%);
    border-top-right-radius: 100px;
    border-top-left-radius:100px;
}

.home-add-btns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 5vh;
}

.home-add-btns>button{
    border: none;
    min-width: 150px;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #FBE0DC;
}

@media screen and (max-width:950px){
    .home-divs{
        flex-direction: column-reverse;
    }
}


