.Error-con{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Error-con>h1{
    font-family: Quicksand;
    padding: 2px;
    font-size: 3.5rem;
    font-weight: 600;
}

.Error-con>button{
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    padding: 10px 20px;
    border: none;
    margin-top: 10px;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #F7C531;
}