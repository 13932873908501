.attacht-con#blur.active{
    filter:blur(20px);
    pointer-events: none;
    user-select: none;
}
.attacht-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.attacht-header>h1{
    font-weight: 600;
    font-family: quicksand;
    text-align: center;
}

.attacht-header>p{
    color: rgb(107, 107, 107);
    font-size: 1.1rem;
    font-family: quicksand;
    font-weight: 500;
    text-align: center;
}
.attacht-vhead{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    user-select: none;
}
.attacht-vhead>h1{
    font-size: 1.7rem;
    font-weight: 700;
    font-family: quicksand;
    text-transform: capitalize;
    text-align: center;
}
.attacht-vhead>p{
    font-size: 1.1rem;
    font-family: quicksand;
    font-weight: 600;
    text-align: center;
}
.attacht-tbtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.attacht-tbtn>button{
    border: none;
    min-width: 150px;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #D8CBF6;
}

.secondpopup,.attachtowns-popup1{
    width: 350px;
    background-color: aqua;
}

.attach-popups1{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}
.attach-popups1>div{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
}
.attach-popups1>div input{
    height: 35px;
    border: none;
    border-bottom: 1.4px solid black;
    padding-left: 10px;
    font-size: 1rem;
    font-family: quicksand;
    font-weight: 500;
    color: green;
}
.attach-popups1>div select{
    height: 35px;
    border: none;
    border-bottom: 1px solid black;
    padding-left: 10px;
    font-size: 1rem;
    font-family: quicksand;
    font-weight: 500;
}
.attach-popups1>div select:focus{
    outline: none;
}
.attach-popups1>div input:focus{
    outline: none;
}

.attach-popups1>div select option{
    font-weight: 600;
    text-transform: capitalize;
}
.attach-popups1>div label{
    font-size: 1.1rem;
    font-family: quicksand;
    font-weight: 600;
}
.attach-popup-btns{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}
.attach-popup-btns button:nth-child(2){
    border: none;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #F7C531;
}
.attach-popup-btns button:nth-child(1){
    border: none;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: rgb(248, 42, 42);
    color: white;
}

#popup2,#popup1{
    position: fixed;
    top: 40%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: white;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0px 11px 40px -25px rgba(0,0,0,0.75);
    border-radius: 13px;
    user-select: none;
   
}

#popup2.active,#popup1.active{
    top: 50%;
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
}

.attachtowns-all-towns{
    margin-top: 20px;
    margin-bottom: 15px;
}

.attachtowns-each-town{
    display: flex;
    flex-direction: column;
    width: 370px;
    margin: auto;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 11px 40px -16px rgba(0,0,0,0.75);
    cursor: pointer;
    user-select: none;
    transition: all 1s ease;
    margin-top: 15px;
    margin-bottom: 15px;
}

.attachtowns-each-town:hover{
    transform: scale(1.045);
}

.attachtowns-each-town1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.attachtowns-each-town2{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.attachtowns-each-town2>p,.attachtowns-each-town1>div p{
    font-family: quicksand;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 4px;
    color: green;
}

.attachtowns-each-town2>p span,.attachtowns-each-town1>div p span{
    color: black;
}
.attachtowns-each-town1>div button{
    border: none;
    min-width: 100px;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #F7C531;
}

.attach-popup-btns-cancel{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.attach-popup-btns-cancel>button{
    border: none;
    padding: 5px 10px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #F7C531;
}

.attach-popup-btns-delete-save{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.attach-popup-btns-delete-save>button:nth-child(1){
    border: none;
    padding: 5px 15px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: rgb(246, 46, 46);
    color: white;
}

.attach-popup-btns-delete-save>button:nth-child(2){
    border: none;
    padding: 5px 15px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: rgb(63, 246, 46);
    color: black;
}