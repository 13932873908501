.Addvillage-con{
    padding-bottom: 20px;
}
.addtown-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addtown-head>h1{
    font-family: Quicksand;
    padding: 2px;
    margin-bottom: 3px;
    font-size: 3rem;
    text-transform: capitalize;
}

.addtown-head>p{
    font-family: quicksand;
    font-weight: 600;
    color: rgb(100, 100, 100);
    text-transform: capitalize;
}

.addtown-form{
    width: 350px;
    margin: auto;
    padding: 10px;
    border: 10px;
    background-color: white;
    user-select: none;
}

.addtown-form>div{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.addtown-form>div label{
    font-size: 1.4rem;
    font-weight: 700;
    color: black;
    font-family: quicksand;
    text-transform: capitalize;
}

.addtown-form>div input{
    height: 40px;
    padding: 5px;
    margin-top: 3px;
    border: 1px solid black;
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
    color: rgb(100, 100, 100);
    font-size: 1rem;
    font-weight: 600;
    font-family: quicksand;
}

.addtown-form>div input::placeholder{
    color: rgb(100, 100, 100);
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 600;
    font-family: quicksand;
}

.addtown-form>.addtown-second-div button{
    min-width: 150px;
    height: 45px;
    padding: 10px 20px;
    border: none;
    font-size: 1.2rem;
    font-family: quicksand;
    font-weight: 600;
    margin: auto;
    text-transform: capitalize;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: green;
    color: white;
}

.all-towns-will-displayhere{
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
}

.all-towns-will-displayhere>h1{
    font-size: 1.3rem;
    font-family: quicksand;
    font-weight: 600;

}
.addtowns-all-towns>div{
    padding:10px 20px;
    margin-top: 10px;
    border-bottom: 2px solid black;
    width: 300px;
    margin: auto;
    text-align: center;
    font-size: 1.1rem;
    font-family: quicksand;
    font-weight: 500;
    color: gray;
}

.adv-list-part1{
    width: 390px;
    margin: auto;
}

.adv-list-part1>div{
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.adv-list-part1 div:nth-child(odd){
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    padding-left: 10px;
    background-color: rgba(237, 237, 237,0.5);
}

.adv-list-part1 div h1{
    font-size: 1.4rem;
    font-weight: 700;
    font-family: quicksand;
    margin-bottom: 5px;
}

.adv-list-part1>div p{
    font-size: 1.1rem;
    font-weight: 700;
    font-family: quicksand;
    margin-bottom: 5px;
    color: grey;
}

.adv-list-part1 div>section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 5px;
}

.adv-list-part1 div>section button{
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    font-size: 1rem;
    font-family: quicksand;
    font-weight: 600;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #F7C531;

}

.adv-list-part1 div:nth-child(even){
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    padding-left: 10px;
    background-color: white;
}


