@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
.nav-con{
    background-color:white;
    padding-top: 30px;
    padding-bottom: 30px;
    position:sticky;
    top:0px;
    z-index: 99;
    
}
.nav-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-left: 5%;
    user-select: none;
    justify-content: space-between;
    margin-right: 5%;
    
}

.nav-logo{
    width: auto;
    height: 40px;
}

.nav-head>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:5px;
}

.nav-head>div h1{
    font-family: Quicksand;
    font-size: 1.6rem;
    font-weight: 700;
}

.nav-logout-btn{
    border: none;
    width: 150px;
    padding: 10px 20px;
    font-family: Quicksand;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 10px;
    user-select: none;
    border-top-left-radius: 25.345px;
    border-bottom-left-radius: 10.3456px;
    border-top-right-radius: 21.654px;
    border-bottom-right-radius: 10.3456px;
    cursor: pointer;
    background-color: #FBE0DC;
}